.separator{
    text-align: center;
}

.separator span{
    display: inline-block;
    border-top: 20px solid rgb(255, 255, 255, 0.9);
    outline: 2px solid var(--primary-color);
    width: 20px;
    height: 8px;
    border-radius: 50%;
    margin: auto 10px;
}

.separator::after,
.separator::before{
    display: inline-block;
    content: '';
    width: 80px;
    height: 10px;
    border-top: 3px solid var(--primary-color);
    position: relative;
}