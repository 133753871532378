.booking-process-container{
    /* background-image: linear-gradient(
        to right bottom,
        rgba(33, 33, 33, 0.8),
        rgba(0, 0, 0, 0.8) 
        ) , url('https://teja12.kuikr.com/is/a/c/1200x500/gallery_images/original/cf5c908c5048bbc.gif'); */
    /* background-image: url('./Assets/Images/Concorde\ elements-03.png'); */
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */

    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: 100vh;
    /* width: 50vw; */
    /* margin: auto; */
    /* position: relative; */
    z-index: 2;
    position: relative;
    overflow: hidden;
}

.background-container{
    position: fixed;
    inset: 0;
    /* bottom: 0; */
    background-image: url('./Assets/Images/Concorde\ elements-03.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(2.5) rotate(306deg) translateY(-10%);
    /* z-index: 2; */
}



/* @media screen and ( min-height: 600px ) and ( min-width: 400px ) {
    .booking-process-container{
        height: 100vh;
    }
} */

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    /* background: rgba(255, 255, 255, 0.2); */
    background-image: url('./Assets/Images/Concorde\ elements-01.png');
    animation: animate 15s linear infinite;
    bottom: -150px;
    background-position: center;
    background-size: contain;
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-100vh) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

@media ( min-width: 800px ) {
    .background-container{
        transform: scale(1.8)  rotate(150deg) translateY(-10%);
        /* transform: translate( -50%, -50%); */
    }
}

@media ( min-width: 900px ) {
    .background-container{
        transform: scale(1.5)  rotate(150deg) translateY(-10%);
        /* transform: translate( -50%, -50%); */
    }
}