/* Utilities for the complete form */
.form-actions{
    --_button-group-padding: 10px;
    display: flex;
    /* flex-direction: column; */
    flex-wrap: reverse;
    justify-content: center;
    padding-left: var(--_button-group-padding);
    padding-right: var(--_button-group-padding);
    gap: 5px;
}

.wrong-input{
    border-color: red !important;
    outline-color: red !important;
}

@media ( min-width: 370px ) {
    .form-actions{
        --_button-group-padding: 20px;
        width: auto;
        gap: 10px;
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;
        padding-left: var(--_button-group-padding);
        padding-right: var(--_button-group-padding);
    }
}