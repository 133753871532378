.input-container {
    display: block;
    width: 100%;
    position: relative;
}

.input-container label {
    align-self: start;
    background-image: linear-gradient(to top, #fff 0 calc(50% - 2px), #fff calc(50% - 2px));
    /* font-size: 1.05rem; */
    font-size: 0.6rem;
    padding-left: 0.5em;
    padding-right: 0.5em;
    transform: translate(0.5rem, -50%);
    position: absolute;
    color: #000;
    font-weight: 700;
    letter-spacing: 1.2px;
    z-index: 2;
    border-radius: 5px;
    outline: 2px solid #000;
    white-space: nowrap;
}

.input-container label span{
    /* font-size: .6rem; */
    font-size: .5rem;
    margin-left: 5px;
    font-weight: 400;
}

.input-container label.required::after {
    content: '*';
    font-family: 'Courier New', Courier, monospace, 'Franklin Gothic Medium' , 'Times New Roman', Times, serif, Arial, Helvetica, sans-serif, Georgia ;
    color: red;
}

.input-container input:focus-visible {
    outline: max(2px, 0.08em) solid var(--primary-color);
    outline-offset: 2px;
}

.input-container input {
    border-radius: 5px;
    width: 100%;
    justify-self: center;
    height: 2.9rem;
    padding: 10px;
    padding-top: 16px;
    display: block;
    margin: auto;
    font-size: 1.1rem;
    border: 2px solid #000;
}

.input-container input[type='file']{
    max-height: none;
    height: 4rem;
}

.input-container input::-webkit-file-upload-button,
.input-container input::file-selector-button {
    font-weight: bold;
    color: rgb(0, 91, 170);
    padding: 0.5em;
    border: thin solid grey;
    border-radius: 3px;
    font-size: 1rem;
}  

.input-container span.remove-icon{
    position: absolute;
    /* height: 0; */
    /* width: 0; */
    right: 2%;
    top: 5%;
    /* transform: translateY(-50%); */
    font-size: 22px;
    padding: 10px;
    border-radius: 100%;
    /* outline: 2px solid red; */
}

.input-container span > *:hover{
    cursor: pointer;
}

.disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.input-container label.disabled {
    background-image: none;
    cursor: not-allowed;
}

@media ( min-width: 330px ) {

    .input-container label {
        font-size: 0.85rem;
        transform: translate(0.7rem,-50%);
    }

    .input-container label span{
        font-size: .8rem;
    }
}

@media ( min-width: 450px ) {
    .input-container label {
        font-size: 1.05rem;
        transform: translate(1.2rem,-50%);
    }
}


/* input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
    color: #fff;
    position: relative;
}

input[type="date"]::-webkit-datetime-edit-day-field {
    position: absolute !important;
    color: #000;
    padding: 4px;
    left: 4px;
}

input[type="date"]::-webkit-datetime-edit-month-field {
    position: absolute !important;
    border-left: 1px solid #8c8c8c;
    padding: 4px;
    color: #000;
    left: 26px;
}

input[type="date"]::-webkit-datetime-edit-year-field {
    position: absolute !important;
    border-left: 1px solid #8c8c8c;
    padding: 2px;
    color: #000;
    left: 56px; 
}*/

