.popup-list-box-container,
.popup-list-box{
    display: block;
    width: 100%;
    position: relative;
}

.popup-list-box{
    border: 2px solid #000;
    border-radius: 5px;
}

.popup-list-box-container > label.pop-up-label{
    align-self: start;
    background-image: linear-gradient(to top, #fff 0 calc(50% - 2px), #fff calc(50% - 2px));
    font-size: 0.6rem;
    padding-left: 0.5em;
    padding-right: 0.5em;
    transform: translate(0.5rem,-50%);
    position: absolute;
    color: #000;
    font-weight: 700;
    letter-spacing: 1.2px;
    z-index: 2;
    border-radius: 5px;
    outline: 2px solid #000;
    white-space: nowrap;
}

.popup-list-box-container label.required::after{
    content: '*';
    font-family: 'Courier New', Courier, monospace, 'Franklin Gothic Medium' , 'Times New Roman', Times, serif, Arial, Helvetica, sans-serif, Georgia ;
    color: red;;
}

.popup-list-box-container label span{
    font-size: .6rem;
    margin-left: 5px;
    font-weight: 400;
}

.popup-list-box {
    margin: 0 auto;
    font-size: 1rem;
    color: #60666d;
}

/* @media (min-width: 768px) {
    .popup-list-box {
        width: 70%;
    }
}

@media (min-width: 992px) {
    .popup-list-box {
        width: 50%;
    }
}

@media (min-width: 1200px) {
    .popup-list-box {
        width: 30%;
    }
} */

.popup-list-box__current {
    position: relative;
    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    cursor: pointer;
    outline: none;
    padding: 2px;
    max-width: inherit;
    max-height: inherit;
    overflow: hidden;
}

.popup-list-box__current:focus + .popup-list-box__list-container .popup-list-box__list {
    opacity: 1;
    animation-name: none;
}

.popup-list-box__current:focus + .popup-list-box__list-container .popup-list-box__list .popup-list-box__option {
    cursor: pointer;
}

.popup-list-box__current:focus + .popup-list-box__list-container .popup-list-box__list li::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 1px solid #000;
}

.popup-list-box__current:focus .popup-list-box__icon {
    transform: translateY(-50%) rotate(180deg);
}

.popup-list-box__current:focus + .popup-list-box__list-container::after{
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background: rgba(160, 160, 160, 0.95);
}

/* .popup-list-box__current:focus + .popup-list-box__list-container::before{
    content: 'x';
    position: fixed;
    top: 50%;
    right: 8vw;
    width: 20px;
    z-index: 11;
    font-size: 1.5rem;
    font-weight: bolder;
    transform: translate(-50%, -50%);
} */


.popup-list-box__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 20px;
    opacity: 0.3;
    transition: 0.2s ease;
    color: #0602ff;
    font-size: 20px;
    font-weight: 900;
}

.popup-list-box__value {
    display: flex;
}

.popup-list-box__input {
    display: none;
}

.popup-list-box__input:checked + .popup-list-box__input-text {
    display: block;
}


.popup-list-box__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 0.8rem;
    font-weight: 700;
    font-size: 1.1rem;
    background-color: #fff;
}


.popup-list-box__list-container{
    position: absolute;
    width: 100%;
}

.popup-list-box__list {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    min-width: 85%;
    padding: 0;
    list-style: none;
    opacity: 0;
    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    
    overflow-y: auto;
    max-height: 80vh;
    z-index: 11;
    border: 2px solid #000;
    outline: max(2px, 0.08em) solid var(--primary-color);
    outline-offset: 5px;
    border-radius: 15px;
}

.popup-list-box__option {
    display: block;
    padding: 15px;
    background-color: #fff;
}

.selected-option,
.popup-list-box__option:hover,
.popup-list-box__option:focus {
    color: #fff;
    background-color: rgba(122, 126, 131, 0.7); 
}  

.selected-option{
    color: #fff;
    background-color: #7293bb; 
}

/* Scroll bar */
.popup-list-box__list::-webkit-scrollbar-track {
    /* border-top: 2px solid #000; */
    /* border-bottom: 2px solid #000; */
    padding: 2px 0;
    /* background-color: #000; */
    border-radius: 10px;
}

.popup-list-box__list::-webkit-scrollbar {
    width: 12px;
}

.popup-list-box__list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
    background-color: #737272;
    /* border: 1px solid #000;  */
}

/*  */

.disabled,
.disabled p{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.popup-list-box-container label.disabled {
    background-image: none;
    cursor: not-allowed;
}


/* Keyframes */

@-moz-keyframes HideList {
    from {
        transform: scaleY(1);
    }

    to {
        transform: scaleY(0);
    }
}

@-webkit-keyframes HideList {
    from {
        transform: scaleY(1);
    }

    to {
        transform: scaleY(0);
    }
}

@-o-keyframes HideList {
    from {
        transform: scaleY(1);
    }

    to {
        transform: scaleY(0);
    }
}

@keyframes HideList {
    from {
        transform: scaleY(1);
    }

    to {
        transform: scaleY(0);
    }
}

/* Media queries */

@media ( min-width: 330px ) {

    .popup-list-box-container > label.pop-up-label {
        font-size: 0.85rem;
        transform: translate(0.7rem,-50%);
    }

    .popup-list-box-container label span{
        font-size: .8rem;
    }
}

@media ( min-width: 450px ) {
    .popup-list-box-container > label.pop-up-label {
        font-size: 1.05rem;
        transform: translate(1.2rem,-50%);
    }
}

@media ( min-width: 800px ) {
    
    .popup-list-box__list{
        min-width: 50%;
        max-width: 70vw;
    }
}

@media ( min-width: 1200px ) {
    .popup-list-box__list{
        min-width: 30%;
        max-width: 50vw;
    }
}

