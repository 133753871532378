.container-header{
    text-align: center;
}

.container-header p{
    margin-top: 10px;
}

.container-body form{
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-top: 20px;
    padding: 0 10px;
}