.instructions-container{
    /* justify-content: center;
    align-items: center; */
    /* display: block; */
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 11px;
}

.instructions-container p{
    font-size: 1.2rem;
}

.tac-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 5px;
}

.tac-container input[type='checkbox']{
    transform: scale(1.2);
}

.instructions-action-group{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}

.instructions-action-group button{
    width: 100%;
}

@media ( min-width: 370px ) {
    .instructions-container{
        width: 85%;
    }
}

@media ( min-width: 550px ) {
    .instructions-action-group{
        gap: 10px;
        flex-wrap: nowrap;
    }

    .instructions-action-group button{
        width: auto;
    }
}