.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: block; */
}

.logo-container img{
    width: 60%;
}

.current-view-info p{
    font-weight: 700;
    font-size: 1.9rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #fff;
    -webkit-text-stroke: 2px var(--primary-color);
    text-align: center;
}

@media ( min-width: 320px ) {
    .logo-container img{
        width: 50%;
    }
}