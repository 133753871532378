
.btn {
    font-size: 1rem;
    padding: 10px;
    border-radius: 10px;
    letter-spacing: 1.2px;
    cursor: pointer;
    text-align: center;
    transition: all 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    border: 1.8px solid #000;
}

i{
    --_icon-margin: 8px;
}

.btn:first-child i{
    margin-right: var(--_icon-margin);
}

.btn:not(:first-child) > i{
    margin-left: var(--_icon-margin);
}

.btn:hover {
    box-shadow: 0px 15px 25px -5px rgba(35, 35, 35, 0.9);
    transform: scale(1);
    /* transform: scale(1.03); */
}

.btn:active {
    box-shadow: 0px 4px 8px rgba(35, 35, 35, 0.9);
    transform: scale(1);
    /* transform: scale(0.98); */
}

.btn.default{
    background-color: var(--primary-color);
    color: #fff;
}

.btn.disabled{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}   

.btn.disabled:hover,
.btn.disabled:active{
    box-shadow: none;
    transform: none;
}