/**
 * Types
 * shadow-loading
 * book-loader
 * form-loader
 */


.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(160, 160, 160, 0.97);
}

.loader-container {
    display: flex;
    align-content: space-around;
    justify-content: center;
}

.loader {
    display: block;
    position: absolute;
    z-index: 11;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.logo-loader {
    width: 100px;
    height: 100px;
    background: linear-gradient(165deg,
            rgba(255, 255, 255, 1) 0%,
            rgb(220, 220, 220) 40%,
            rgb(170, 170, 170) 98%,
            rgb(10, 10, 10) 100%);
    background-image: url('../../../Assets/Images/C\ ICON\ -01.png');
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    animation: lds-circle 5.4s cubic-bezier(.42,0,.58,1) infinite;
}

@keyframes lds-circle {
    0%,
    100% {
        animation-timing-function: cubic-bezier(.42,0,.58,1);
        translate: -50% -50%;
    }

    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(.42,0,.58,1);
    }

    100% {
        transform: rotateY(3600deg);
    }
}

.shadow-loading {
    width: 100px;
    height: 100px;
    background: linear-gradient(165deg,
            rgba(255, 255, 255, 1) 0%,
            rgb(220, 220, 220) 40%,
            rgb(170, 170, 170) 98%,
            rgb(10, 10, 10) 100%);
    background-image: url('../../../Assets/Images/C\ ICON\ -01.png');
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
}

.shadow-loading:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 100%;
    /* padding: 60px;
    left: -12%;
    top:  -10%; */
    border-bottom: 0 solid #ffffff05;
    /* box-shadow: 0 -10px 20px 20px #ffffff40 inset,
        0 -5px 15px 10px rgba(0, 91, 170, 0.5) inset, 0 -2px 5px rgba(0, 91, 170, 0.5) inset,
        0 -3px 2px rgba(0, 91, 170, 0.5) inset, 0 2px 0px rgba(0, 91, 170, 0.5), 0 2px 3px rgba(0, 91, 170, 0.5),
        0 5px 5px rgba(0, 91, 170, 0.5), 0 10px 15px rgba(0, 91, 170, 0.5), 0 10px 20px 20px rgba(0, 91, 170, 0.5); */
    box-shadow: 0 -10px 20px 20px #ffffff40 inset,
        0 -5px 15px 10px #ffffff50 inset, 0 -2px 5px #ffffff80 inset,
        0 -3px 2px #ffffffbb inset, 0 2px 0px #ffffffbb, 0 2px 3px #ffffffbb,
        0 5px 5px #ffffff90, 0 10px 15px #ffffff60, 0 10px 20px 20px #ffffff40;
    filter: blur(3px);
    animation: 2s rotate linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg)
    }
}

/* Book Loader */

.book-loader {
    width: 200px;
    height: 140px;
    background: #979794;
    box-sizing: border-box;
    border-radius: 8px;
    perspective: 1000px;
}

.book-loader:before {
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius: 8px;
    background: #f5f5f5 no-repeat;
    background-size: 60px 10px;
    background-image: linear-gradient(#ddd 100px, transparent 0),
        linear-gradient(#ddd 100px, transparent 0),
        linear-gradient(#ddd 100px, transparent 0),
        linear-gradient(#ddd 100px, transparent 0),
        linear-gradient(#ddd 100px, transparent 0),
        linear-gradient(#ddd 100px, transparent 0);

    background-position: 15px 30px, 15px 60px, 15px 90px,
        105px 30px, 105px 60px, 105px 90px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    outline: 2px solid var(--primary-color);
}

.book-loader:after {
    content: '';
    position: absolute;
    width: calc(50% - 10px);
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius: 8px;
    background: #fff no-repeat;
    background-size: 60px 10px;
    background-image: linear-gradient(#ddd 100px, transparent 0),
        linear-gradient(#ddd 100px, transparent 0),
        linear-gradient(#ddd 100px, transparent 0);
    background-position: 50% 30px, 50% 60px, 50% 90px;
    transform: rotateY(0deg);
    transform-origin: left center;
    animation: paging 1s linear infinite;
}


@keyframes paging {
    to {
        transform: rotateY(-180deg);
    }
}

/* Form Loader */

.form-loader {
    width: 100px;
    height: 130px;
    background: #fff;
    border-radius: 4px;
}

.form-loader:before {
    content: '';
    position: absolute;
    width: 54px;
    height: 25px;
    left: 50%;
    top: 0;
    background-image:
        radial-gradient(ellipse at center, #0000 24%, #de3500 25%, #de3500 64%, #0000 65%),
        linear-gradient(to bottom, #0000 34%, #de3500 35%);
    background-image:
        radial-gradient(ellipse at center, #0000 24%, #004DAA 25%, #004DAA 64%, #0000 65%),
        linear-gradient(to bottom, #0000 34%, #004DAA 35%);
    background-size: 12px 12px, 100% auto;
    background-repeat: no-repeat;
    background-position: center top;
    transform: translate(-50%, -65%);
    box-shadow: 0 -3px rgba(0, 0, 0, 0.25) inset;
}

.form-loader:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    width: 66%;
    height: 60%;
    background: linear-gradient(to bottom, #004DAA 30%, #0000 31%);
    background-size: 100% 16px;
    animation: writeDown 2s ease-out infinite;
}

@keyframes writeDown {
    0% {
        height: 0%;
        opacity: 0;
    }

    20% {
        height: 0%;
        opacity: 1;
    }

    80% {
        height: 65%;
        opacity: 1;
    }

    100% {
        height: 65%;
        opacity: 0;
    }
}