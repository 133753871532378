*,
*::after,
*::before{
    padding: 0;
    margin: 0;
	box-sizing: border-box;
}

@font-face {
    font-family: 'Intro';
    src: url(./Assets/Fonts/Intro\ Regular.otf) format('opentype');
}

:root{
    --primary-color: #004DAA;
    --com-bg-clr: rgba(255, 255, 255, 0.9)
}

body, p{
    font-family: 'Intro';
    /* font-family: 'Raleway', sans-serif; */
    /* font-family: 'Roboto Condensed', sans-serif; */
    /* border: 5px solid var(--primary-color); */
}

