.address-main-container{
    display: block;
    width: 100%;
    position: relative;
    border: 2px solid #000;
    border-radius: 15px;
}

.address-main-container label {
    align-self: start;
    background-image: linear-gradient(to top, #fff 0 calc(50% - 2px), #fff calc(50% - 2px));
    /* font-size: 1.05rem; */
    font-size: 0.6rem;
    padding-left: 0.5em;
    padding-right: 0.5em;
    transform: translate(0.5rem, -50%);
    position: absolute;
    color: #000;
    font-weight: 700;
    letter-spacing: 1.2px;
    z-index: 2;
    border-radius: 5px;
    outline: 2px solid #000;
    white-space: nowrap;
}

.address-main-container label.required::after {
    content: '*';
    font-family: 'Courier New', Courier, monospace, 'Franklin Gothic Medium' , 'Times New Roman', Times, serif, Arial, Helvetica, sans-serif, Georgia ;
    color: red;
}

.address-main-container label span{
    /* font-size: .6rem; */
    font-size: .5rem;
    margin-left: 5px;
    font-weight: 400;
}

.address-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1.3rem;
    padding: 20px;
    margin-top: 15px;
}

.address-container .two-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.wrong-input{
    border-color: red !important;
    outline-color: red !important;
}

.address-main-container label.disabled {
    background-image: none;
    cursor: not-allowed;
}

.disabled {
    /* border: 1px solid #999999; */
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

@media ( min-width: 330px ) {

    .address-main-container label {
        font-size: 0.85rem;
        transform: translate(0.7rem,-50%);
    }

    .address-main-container label span{
        font-size: .8rem;
    }
    
    .address-container .two-input-container{
        flex-direction: row;
    }
}

@media ( min-width: 450px ) {
    .address-main-container label {
        font-size: 1.05rem;
        transform: translate(1.2rem,-50%);
    }
}
