.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9;
    background: rgba(160, 160, 160, 0.95);
}


.modal {
    position: fixed;

    /* height: 30vh; */
    width: 90vw;
    top: 50%;
    left: 50%;
    z-index: 10;

    background: #fff;
    border-radius: 3px;
    box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.4);
    text-align: center;
    overflow: hidden;
    animation: show-modal 0.2s ease-in-out;
    transform: translate(-50%, -50%);

}

.hide {
    animation: hide-modal 0.6s ease-in-out both;
}

.modal img {
    margin-top: 24px;
}

.modal .title {
    display: block;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin: 14px 0 5px 0;
}

.modal p {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 19px;
    margin: 0;
    padding: 20px 30px;
}

.modal .button {
    /* position: absolute; */
    height: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f65656;
    color: #fff;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.modal .button:hover {
    background: #ec3434;
}

@media ( min-width: 450px) {
    .modal{
        /* height: 30vh; */
        width: 60vw;
    }
}

@media ( min-width: 680px) {
    .modal{
        /* height: 30vh; */
        max-width: 80vw;
    }
}

@media ( min-width: 800px) {
    .modal{
        /* height: 25vh; */
        max-width: 30vw;
    }
}

@keyframes show-modal {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes hide-modal {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}