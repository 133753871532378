.container {
    --_blue-box-shodow-color: rgba(0, 77, 170, 0.79);
    --_gray-box-shodow-color: rgba(63, 64, 64, 0.79);
    --_container-box-shadow-color: var(--_gray-box-shodow-color);

    padding: 5px;
    /* padding: 25px; */
    background-color: var(--com-bg-clr);
    border-radius: 8px;
    max-height: 90%;
    /* height: 100%; */
    background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 4px, transparent 4px) 0% 0%/8px 8px no-repeat,
                radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 4px, transparent 4px) 100% 0%/8px 8px no-repeat,
                radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 4px, transparent 4px) 0% 100%/8px 8px no-repeat,
                radial-gradient(circle at 0 0, #ffffff 0, #ffffff 4px, transparent 4px) 100% 100%/8px 8px no-repeat,
                linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 8px) calc(100% - 16px) no-repeat,
                linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 8px) no-repeat,
                repeating-linear-gradient(355deg, #a91cad 0%, #004daa 14%, rgba(0, 77, 170, 1) 20%, rgba(0, 77, 170, 1) 20%, #004daa 30%, #004daa 50%, #009fff 57%, #009fff 80%, #004daa 90%);


    box-shadow: 1px 11px 34px 6px var(--_container-box-shadow-color);
    -webkit-box-shadow: 1px 11px 34px 6px var(--_container-box-shadow-color);
    -moz-box-shadow: 1px 11px 34px 6px var(--_container-box-shadow-color);
    /* position: relative; */
    opacity: .95;
    /* overflow: auto; */
    z-index: 1;
    /* -webkit-mask: linear-gradient(transparent, #ee5297 1.5%, #ee5297 99%, transparent ); */
    /* mask: linear-gradient(transparent, #ee5297 1.5%, #ee5297 99%, transparent ); */
    display: -webkit-box;
    overflow-x: hidden;

    /* From https://css.glass */
    /* background: rgba(255, 255, 255, 0.5);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 1); */


    /* background: rgba( 255, 255, 255, 0.35 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 15px );
    -webkit-backdrop-filter: blur( 15px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 ); */

    /* background: rgba( 255, 255, 255, 0.5 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 140px );
    -webkit-backdrop-filter: blur( 140px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 ); */
}

.container div.container-div{
    padding: 30px 25px;
    overflow: auto; 
    max-width: 100%;
}

.container div.container-div::-webkit-scrollbar-track,
.container::-webkit-scrollbar-track {
    border: 1px solid #000;
    padding: 2px 0;
    background-color: #fff;
    border-radius: 17px;
}

.container div.container-div::-webkit-scrollbar,
.container::-webkit-scrollbar {
    width: 8px;
}

.container div.container-div::-webkit-scrollbar-thumb,
.container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #737272;
    border: 1px solid #000; 
}

@media (min-width: 560px) {
    .container {
        max-width: 85%;
        max-height: 90%;
    }
}

@media (min-width: 1000px) {
    .container {
        max-width: 40%;
        max-height: 90%;
    }
}

@media screen and (max-height: 478px) {

    .container{
        /* padding: 15px; */
        position: relative;
    }

    /* .container > div{
        width: 100%;
        height: 100%;
        overflow: auto;
        position: absolute;
        margin: -20px;
        padding: 15px;
    } */

}