.price-sheet-container{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
}

.price-sheet-name{
    padding: 10px;
    background-color: #585858;
    color: #fff;
    border-radius: 5px;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.price-sheet-actions{
    --_button-group-padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: center;
    padding-left: var(--_button-group-padding);
    padding-right: var(--_button-group-padding);
    gap: 5px;
}

@media ( min-width: 370px ) {
    .price-sheet-actions{
        --_button-group-padding: 20px;
        width: auto;
        gap: 10px;
        justify-content: center;
        flex-wrap: nowrap;
        padding-left: var(--_button-group-padding);
        padding-right: var(--_button-group-padding);
    }

    .price-sheet-name{
        font-size: 1.5rem;
    }
}

@media (min-width: 560px) {
    .btn-rm-border{
        border: none;
    }
}
